<template>
  <v-row class="NewPasswordSuccessContainer">
    <v-col>
      <v-row>
        <v-col sm="12" md="6" lg="6">
          <v-row>
            <v-col>
              <div class="brandContainerNewPassword">
                <v-img
                  :src="require('@/assets/general/logo.png')"
                  max-height="40"
                  max-width="40"
                ></v-img>
                <span class="textDecorationBold mr-1">REALTOR</span
                ><span
                  class="textFontDecoration"
                  style="font-style: normal; font-weight: normal"
                >
                  VOICE</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="margin-top: 5%">
        <v-col
          md="12"
          sm="12"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div>
            <v-form>
              <v-container>
                <v-row>
                  <v-col>
                    <span
                      class="textDecorationBold"
                      style="font-size: 34px; color: var(--v-primary-base)"
                      ><b>{{ $t("newPasswordSuccess") }}</b></span
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <v-row>
                        <v-col>
                          <v-container class="imgContainer">
                            <v-img
                              :src="
                                require('@/assets/general/newPasswordSuccess.svg')
                              "
                            ></v-img>
                          </v-container>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 3%;
                  "
                >
                  <v-row class="mb-10">
                    <v-col>
                      <v-btn
                        large
                        outlined
                        color="#263573"
                        @click="loginRedirect"
                        style="width: 100%"
                        class="textFontDecoration btnContainer"
                      >
                        {{ $t("backToLogin") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-container class="imgContainer">
                        <v-img
                          :src="require('@/assets/general/footer.png')"
                        ></v-img>
                      </v-container>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/* 
  Name:NewPasswordSuccess
  Description: NewPasswordSuccess view is a page that appears when user changed the password.
*/
export default {
  name: "NewPasswordSuccess",
  created() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    loginRedirect() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.NewPasswordSuccessContainer {
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(230, 235, 247, 1) 100%
  );
}
.btnContainer {
  text-transform: capitalize;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
}

.imgContainer {
  display: flex;
  align-items: center;
  width: 80%;
}

.brandContainerNewPassword {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  margin-left: 3%;
}

@media screen and (max-width: 959px) {
  .imgContainer {
    display: flex;
    align-items: center;
    width: 320px;
  }
}
</style>
